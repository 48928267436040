.inside-intercom-blog-posts {
  @include twelve-columns-to-eight-columns-grid;
  padding: 3rem 0 0;
}

.inside-intercom-blog-post {
  @include four-col-item-in-grid;
  padding-bottom: $rhythm;
}

.inside-intercom-blog-post>p:last-child {
  margin-bottom: 0;
}

.inside-intercom-blog-post-image {
  width: 100%;
  height: 0;
  padding-bottom: 47.3404%;
  background-size: cover;
  display: block;
  margin-bottom: ($rhythm / 2);
}

.inside-intercom-blog-post-heading {
  margin: 0;
}

.inside-intercom-blog-posts-category-selection-back-to-essays {
  display: none;
}

.inside-intercom-blog-posts-category-selection {
  @include rules-for-medium-view-and-wide-view {
    background-size: 366px 1px;
    background-position: center top;
    background-repeat: no-repeat;
    position: sticky;
    top: $header-height-with-grid;
    min-height: $rhythm;
    padding: ((3.75rem - $rhythm) / 2) 7.5rem ((3.75rem - $rhythm) / 2) 0;
    background-color: $color-white;
    transition-duration: $standard-transition-duration;
    transition-property: box-shadow;
    text-align: left;
    .inside-intercom-blog-posts-category-selection-back-to-essays {
      display: block;
      opacity: 0;
      text-align: right;
      width: 7.5rem;
      position: absolute;
      right: 0;
      top: 0;
      padding: ((3.75rem - $rhythm) / 2) 0;
      transition-duration: $standard-transition-duration;
      transition-property: opacity;
    }
    &.mod__sticky {
      box-shadow: 0 25px 30px -27px rgba($color-dark-gray, 0.25);
      background-image: linear-gradient(90deg, rgba($color-dark-gray,0) 0%, rgba($color-dark-gray,0.2) 50%, rgba($color-dark-gray,0) 100%);
      .inside-intercom-blog-posts-category-selection-back-to-essays {
        opacity: 1;
      }
    }
  }
  @include rules-for-wide-view {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
}

.inside-intercom-blog-posts-category-selection-list {
  @include rules-for-wide-view {
    text-align: center;
  }
}

.inside-intercom-blog-posts-category-selection-list-opener,
.inside-intercom-blog-posts-category-selection-list a {
  display: inline-block;
  margin: 0 0.5rem 0 0;
}

.inside-intercom-blog-posts-category-selection-list a:last-child {
  margin-right: 0;
}

.inside-intercom-blog-posts-category-selection-list a.selected {
  @include body-text-style;
  text-decoration: none;
  font-style: normal;
}
