html {
  min-height: 100vh;
}

body {
  @include body-text-style;
}

body,
.main-header {
  @include rules-for-medium-view-and-wide-view {
    @include yellow-bar;
  }
}

p {
  margin: 1.5rem 0;
}

a {
  @include standard-link-style;
}

i, em {
  @include standard-italic-style;
}

.subtle-italic {
  @include subtle-italic-style;
}

.text-title {
  @include title-style;
}

.text-large-heading {
  @include large-heading-style;
}

.text-small-heading {
  @include small-heading-style;
}

.text-small-heading a {
  @include small-heading-style;
  text-decoration: none;
}

.text-small-heading a:hover {
  text-decoration: underline;
}

.text-tiny-heading {
  @include tiny-heading-style;
}

.text-meta {
  @include body-text-style;
  font-style: italic;
}

.text-meta em {
  @include body-text-style;
  color: $heading-color;
}

.text-subtle-emphasis {
  @include standard-italic-style;
  font-weight: 400;
}

.text-link-list {
  @include rules-for-medium-view-and-wide-view {
    margin: 1.5rem 0;
    padding: 0;
    list-style-type: none;
  }
}

.text-link-list li {
  @include rules-for-medium-view-and-wide-view {
    display: inline-block;
    &::before {
      content: "\00a0\00a0\00a0|\00a0\00a0\00a0";
      color: $color-light-gray;
    }
    &:first-child::before {
      content: "";
    }
  }
}

.link-with-inner {
  text-decoration: none;
}

.link-with-inner-inner {
  text-decoration: underline;
}

.text-meta.mod__use-white-text,
.text-title.mod__use-white-text,
.link-with-inner.mod__use-white-text {
  color: $color-white;
}

.hide-in-small-view {
  @media screen and (max-width: $linear-max-width) {
    display: none;
  }
}

.hide-in-medium-view {
  @media screen
    and (max-width: $twelve-column-min-width - 1)
    and (min-width: $eight-column-min-width) {
      display: none;
  }
}

.hide-in-wide-view {
  @include rules-for-wide-view {
    display: none;
  }
}
