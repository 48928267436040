.investments {
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
  }
  @include rules-for-wide-view {
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.investment {
  @include rules-for-medium-view-and-wide-view {
    grid-column-start: auto;
    grid-column-end: span 4;
    &:nth-child(2n + 1)  {
      padding-bottom: 6rem;
      margin-top: -6rem;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2)  {
      padding-top: 6rem;
    }
  }
  @include rules-for-wide-view {
    grid-column-end: span 3;
    &:nth-child(3) {
      margin-top: 0;
    }
    &:nth-child(4) {
      padding-top: 6rem;
    }
  }
}

.investment-logo {
  width: 100%;
  display: block;
  padding-bottom: 1.5rem;
  @include rules-for-medium-view-and-wide-view {
    height: 6rem;
    display: flex;
    justify-content: center;
    padding-bottom: 0.75rem;
  }
}

.investment-logo-media {
  display: block;
  margin: 0 auto;
  max-width: 50%;
  @include rules-for-medium-view-and-wide-view {
    max-height: 100%;
    object-fit: scale-down;
    align-self: center;
  }
}

.investment-description p {
  margin-top: 0;
  @include rules-for-medium-view-and-wide-view {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
