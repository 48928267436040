@function path-background($path-number) {
  @if $path-number == 0 {
    @return url('/img/writing/paths/6.png');
  } @else {
    @return url('/img/writing/paths/#{$path-number}.png');
  }
}

@mixin path($path-number) {
  $previous-path-number: $path-number - 1;
  @include rules-for-medium-view-and-wide-view {
    .essays-list-item:nth-last-child(6n+#{$path-number}) {
      background-image: path-background($path-number), path-background($previous-path-number);
      &:first-child {
        background-image: none, path-background($previous-path-number);
      }
    }
  }
}

.essays {
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
  }
  @include rules-for-wide-view {
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.essays-list {
  @include rules-for-medium-view-and-wide-view {
    grid-column-start: auto;
    grid-column-end: span 8;
  }
  @include rules-for-wide-view {
    grid-column-start: 3;
  }
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.essays-list-item {
  padding: 0;
  margin: 0;
  text-align: center;
  display: block;
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: 0;
    grid-template-columns: repeat(2, 1fr [col-start]);
    background-size: 56.9587629% 168px, 56.9587629% 168px;
    background-position: top -84px center, bottom -84px center;
    background-repeat: no-repeat;
    .essays-list-item-image {
      display: grid;
    }
    .essays-list-item-image,
    .essays-list-item-text {
      grid-column-start: auto;
      grid-column-end: span 1;
      grid-row-start: 1;
    }
    .essays-list-item-text {
      padding: 5rem $gutter-width;
    }

    &:nth-last-child(2n) {
      .essays-list-item-image {
        grid-column-start: 2;
      }
      .essays-list-item-text {
        grid-column-start: 1;
      }
    }
  }
}

@for $i from 1 through 6 {
   @include path($i);
}

@include rules-for-medium-view-and-wide-view {
  .essays-list-item:last-child {
    background-image: path-background(1), none;
    &:first-child {
      background-image: none, none;
    }
  }
}

.essays-list-item:last-child {
  @include rules-for-medium-view-and-wide-view {
    padding-bottom: 0;
  }
}

.essays-list-item-image,
.essays-list-item-text {
  p {
    margin: 0 0 0.75rem;
  }
  padding-bottom: $rhythm;
}

.essays-list-item-image-media {
  display: block;
  margin: auto;
  max-width: 100%;
  border: none;
}

.essays-list-item-image.mod__expanded {
  /* TODO: Should we implement scaling here? */

  /* .essays-list-item-image-media {
    transform: scale(0.473684211);
  }
  */
  &.mod__left-aligned .essays-list-item-image-media {
    margin: auto auto auto 0;
  }
  &.mod__right-aligned .essays-list-item-image-media {
    margin: auto 0 auto auto;
  }
}

.essay {
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
  }
  @include rules-for-wide-view {
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.essay-back-link {
  padding-top: $rhythm;
  @include rules-for-medium-view-and-wide-view {
    padding-top: $rhythm * 1.5;
    grid-column-start: 1;
    grid-column-end: span 1;
    width: 100%;
  }
  @include rules-for-wide-view {
    grid-column-end: span 3;
  }
}

.essay-header-text {
  padding-top: $rhythm;
  @include rules-for-medium-view-and-wide-view {
    padding-top: 0;
    grid-column-start: 2;
    grid-column-end: span 6;
  }
  @include rules-for-wide-view {
    grid-column-start: 4;
  }

  .text-meta {
    margin: 0;
    text-align: center;
  }
}

.essay-header-inner {
  padding: 0 2rem;
  @include rules-for-medium-view-and-wide-view {
    position: relative;
    max-width: 90rem;
    margin: 0 auto;
    padding: 0 ($standard-page-margin + $yellow-bar-width);
    grid-column-start: auto;
    grid-column-end: span 8;
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
    .text-meta {
      margin-top: 0;
    }
  }
  @include rules-for-wide-view {
    grid-column-end: span 12;
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.essay-header {
  background-repeat: no-repeat;
  background-size: contain;
  @include rules-for-medium-view-and-wide-view {
    margin-top: 3 * $rhythm;
    .essay-back-link {
      padding-bottom: 0;
    }
    .essay-header-inner {
      padding-bottom: 0;
    }
    .essay-header-text {
      grid-column-start: 2;
      grid-column-end: span 6;
    }
    .text-meta {
      margin-top: 0;
    }
  }
  @include rules-for-wide-view {
    .essay-header-text {
      grid-column-start: 4;
    }
  }
}

.essay-header.mod__with-background-image {
  margin-top: $rhythm * 3;
  &.mod__with-background-image-fully-stretched {
    margin-top: 0;
    padding-top: $rhythm * 3;
  }
  .essay-header-inner {
    padding-bottom: $rhythm;
  }
  .essay-back-link {
    position: relative;
    top: $rhythm * -3;
  }
  .essay-header-text {
    padding-top: ($rhythm * 6);
  }
  @include rules-for-medium-view-and-wide-view {
    margin-top: 0;
    &.mod__with-background-image-fully-stretched {
      padding-top: 0;
    }
    .essay-back-link {
      top: auto;
      padding-top: 4 * $rhythm;
      position: absolute;
    }
    .essay-header-text {
      padding-top: $rhythm * 6;
    }
  }
  @include rules-for-wide-view {
    .essay-header-text {
      padding-top: $rhythm * 5;
    }
    .essay-header-inner {
      padding-top: $rhythm * 5;
      padding-bottom: $rhythm * 3;
    }
  }
}

.essay-header.mod__with-left-text {
  @include rules-for-medium-view-and-wide-view {
    .essay-header-text {
      grid-column-start: 1;
    }
  }
}

.essay-header.mod__with-right-text {
  @include rules-for-medium-view-and-wide-view {
    .essay-header-text {
      grid-column-start: 3;
    }
    .essay-back-link {
      padding-top: $rhythm;
      grid-column-start: 8;
      text-align: right;
      .link-with-inner-hideable {
        display: none;
      }
    }
  }
  @include rules-for-wide-view {
    .essay-header-text {
      grid-column-start: 7;
    }
    .essay-back-link {
      grid-column-start: 10;
    }
  }
}

.essay-header.mod__with-background-image-position-left {
  background-position: top left;
  @include rules-for-medium-view-and-wide-view {
    background-position: $yellow-bar-width 0;
  }
}

.essay-header.mod__with-background-image-position-right {
  background-position: top right;
}

.essay-header.mod__with-background-image-position-center {
  background-position: top center;
}

.essay-header.mod__with-background-image-position-cover {
  background-size: cover;
  background-position: center center;
}

.essay-body {
  h2 {
    @include large-heading-style;
    margin: 2.625rem 0 -1rem;
  }
  h3 {
    @include small-heading-style;
  }

  strong, b {
    font-family: $serif-face;
    font-weight: 600;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .essay-wide-image {
    @include rules-for-medium-view-and-wide-view {
      margin: $rhythm calc((100% + #{$gutter-width}) / -6);
    }
    @include rules-for-wide-view {
      margin: $rhythm calc((100% + #{$gutter-width}) / -3);
    }
  }

  @include rules-for-medium-view-and-wide-view {
    grid-column-start: 2;
    grid-column-end: span 6;

    ul,
    ol {
      padding-left: 0;
      margin: $rhythm 0;
      li {
        padding-bottom: $rhythm / 2;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    ol {
      list-style: none;
      counter-reset: essay-body-ol-counter;
      li {
        counter-increment: essay-body-ol-counter;
        position: relative;
        &::before {
          content: counter(essay-body-ol-counter) ". ";
          font-family: $serif-face;
          display: inline-block;
          text-align: right;
          width: 3.5rem;
          position: absolute;
          left: -(3.5rem + ($rhythm / 2));
          top: 0;
        }
      }
    }
  }

  @include rules-for-wide-view {
    grid-column-start: 4;
  }
}

.essay-footer {
  text-align: center;
  @include rules-for-medium-view-and-wide-view {
    grid-column-start: 2;
    grid-column-end: span 6;
  }
  @include rules-for-wide-view {
    grid-column-start: 4;
  }
}
