.footer {
  text-align: center;
  margin: $rhythm auto 0;
  max-width: $max-text-block-width;
  padding: $rhythm 0 ($rhythm * 2);
  @include rules-for-medium-view-and-wide-view {
    padding: $rhythm ($standard-page-margin + $yellow-bar-width) ($rhythm * 2);
  }
  hr {
    margin-bottom: 2.9375rem;
  }
  a {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    &:first-of-type {
      margin-left: 10px;
    }
  }
}
