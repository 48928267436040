/* Raw palette data */
$color-black: #000000;
$color-dark-gray: #454a4c;
$color-light-gray: #d0d2d2;
$color-red: #e1315b;
$color-blue: #008dcb;
$color-yellow: #ffec5c;
$color-white: #ffffff;

/* Use colors in intelligible names */
$link-color: $color-blue;
$heading-color: $color-red;
$text-color: $color-dark-gray;
$increased-emphasis-color: $color-red;

/* Widths and heights */
$header-height-with-grid: 5.25rem;
$max-width: 1176px;
$max-text-block-width: 576px;
$gutter-width: 24px;
$horizontal-margin: 71px;
$twelve-column-min-width: 800px + (($gutter-width + $horizontal-margin) * 2) + 1px;
$eight-column-min-width: 530px + (($gutter-width + $horizontal-margin) * 2) + 1px;
$linear-max-width: $eight-column-min-width - 1px;
$rhythm: 1.5rem;
$yellow-bar-width: 2.8125rem;
$standard-page-margin: 3.125rem;

/* Other global values */
$standard-transition-duration: 0.2s;

@mixin rules-for-wide-view {
  @media screen and (min-width: $twelve-column-min-width), print {
    @content;
  }
}

@mixin rules-for-medium-view-and-wide-view {
  @media screen and (min-width: $eight-column-min-width), print {
    @content;
  }
}

@mixin span-n-grid-columns($columns-to-span) {
  grid-column-start: auto;
  grid-column-end: span $columns-to-span;
}

@mixin adapting-grid($wide-view-columns, $medium-view-columns: null) {
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    @if ($medium-view-columns) {
      grid-template-columns: repeat($medium-view-columns, 1fr [col-start]);
    } @else {
      grid-template-columns: repeat($wide-view-columns, 1fr [col-start]);
    }
  }
  @if ($medium-view-columns) {
    @include rules-for-wide-view {
      grid-template-columns: repeat($wide-view-columns, 1fr [col-start]);
    }
  }
}

/* Text */
$sans-face: 'Alegreya Sans', sans-serif;
$serif-face: 'Alegreya', serif;
$body-text-size: 1.125rem;

@mixin heading-defaults {
  color: $heading-color;
  font-family: $serif-face;
  font-weight: 400;
  margin: 0;
}

@mixin body-text-size-and-line-height {
  font-size: $body-text-size;
  line-height: $rhythm;
}

@mixin body-text-style {
  @include body-text-size-and-line-height;
  font-family: $sans-face;
  color: $text-color;
}

@mixin title-style {
  @include heading-defaults;
  text-align: center;
  font-size: 2.25rem;
  line-height: 2.8125rem;
  @include rules-for-medium-view-and-wide-view {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
}

@mixin large-heading-style {
  @include heading-defaults;
  /* TODO: figure out when this should kick in */
  font-size: 2.25rem;
  line-height: 2.8125rem;
}

@mixin small-heading-style {
  @include heading-defaults;
  /* TODO: figure out when this should kick in */
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  font-style: normal;
}

@mixin tiny-heading-style {
  @include heading-defaults;
  @include body-text-size-and-line-height;
}

@mixin standard-italic-style {
  font-family: $serif-face;
  font-weight: 500;
  font-style: italic;
}

@mixin subtle-italic-style {
  font-family: $sans-face;
  font-weight: 400;
  font-style: italic;
}

@mixin standard-link-style {
  @include standard-italic-style;
  color: $link-color;
}

@mixin podcast-box-shadow {
  box-shadow: 0 0 2px 2px $color-white, 0 1px 8px 3px rgba($color-dark-gray, .3);
}

@mixin twelve-columns-to-eight-columns-grid {
  @include adapting-grid(12, 8);
}

@mixin four-col-item-in-grid {
  margin: 1.5rem 0;
  @include rules-for-medium-view-and-wide-view {
    margin: 0;
    @include span-n-grid-columns(4);
  }
}

@mixin yellow-bar {
  background-size: $yellow-bar-width 0.0625rem;
  background-image: linear-gradient(90deg, rgba(255,236,92,1) 0%, rgba(255,236,92,1) 45%, rgba(253,214,88,1) 100%);
  background-position: 0 0;
  background-repeat: repeat-y;
  background-color: $color-white;;
}
