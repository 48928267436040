.inside-intercom-intro {
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
  }
  @include rules-for-wide-view {
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.inside-intercom-intro-text {
  @include rules-for-medium-view-and-wide-view {
    grid-column-start: auto;
    grid-column-end: span 6;
  }
}

.inside-intercom-podcast-link {
  display: none;
  @include rules-for-medium-view-and-wide-view {
    display: block;
    grid-column-start: 7;
    grid-column-end: span 2;
    align-self: center;
  }
  @include rules-for-wide-view {
    grid-column-start: 9;
  }
}

.inside-intercom-podcast-link-media {
  max-width: 100%;
  @include podcast-box-shadow;
}

@import "inside-intercom-appearances";
