.external-podcast-appearances {
  padding-top: 1.5rem;
  @include rules-for-medium-view-and-wide-view {
    padding: 3rem 0;
    display: grid;
    grid-column-gap: $gutter-width;
    grid-row-gap: $rhythm;
    grid-template-columns: repeat(8, 1fr [col-start]);
  }
  @include rules-for-wide-view {
    grid-template-columns: repeat(12, 1fr [col-start]);
  }
}

.external-podcast-appearance {
  margin-bottom: $rhythm;
  clear: both;
  @include rules-for-medium-view-and-wide-view {
    display: grid;
    grid-row-gap: $rhythm;
    grid-column-gap: $gutter-width;
    grid-column-start: auto;
    grid-column-end: span 4;
    grid-template-columns: repeat(4, 1fr [col-start]);
    margin-bottom: 0;
  }
  @include rules-for-wide-view {
    grid-column-end: span 6;
    grid-template-columns: repeat(6, 1fr [col-start]);
  }
}

.external-podcast-appearance-logo {
  float: right;
  padding: 0 0 0 $gutter-width;
  width: 25%;
  @include rules-for-medium-view-and-wide-view {
    width: auto;
    float: none;
    padding: 0;
    grid-column-start: auto;
    grid-column-end: span 1;
  }
  @include rules-for-wide-view {
    grid-column-end: span 2;
  }
}

.external-podcast-appearance-logo-media {
  max-width: 100%;
  @include podcast-box-shadow;
}

.external-podcast-appearance-text {
  grid-column-start: auto;
  grid-column-end: span 4;
  @include rules-for-medium-view-and-wide-view {
    grid-column-end: span 3;
  }
  @include rules-for-wide-view {
    grid-column-end: span 4;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
