$main-header-interior-height: 3.375rem;

.main-content {
  margin: 3rem 0;
  padding: 0 2rem;
  @include rules-for-medium-view-and-wide-view {
    max-width: 90rem;
    margin: 0 auto;
  }
}

.main-content,
.main-header {
  @include rules-for-medium-view-and-wide-view {
    padding: 0 ($standard-page-margin + $yellow-bar-width);
  }
}

$main-header-vertical-padding: (($header-height-with-grid - $main-header-interior-height) / 2);

.main-header {
  background-color: $color-yellow;
  padding: $main-header-vertical-padding 0;
  position: sticky;
  top: 0;
  text-align: center;
  max-height: 100%;
  box-sizing: border-box;
  @include rules-for-medium-view-and-wide-view {
    padding: $main-header-vertical-padding $standard-page-margin $main-header-vertical-padding ($standard-page-margin + $yellow-bar-width);
    margin: 0 auto;
    @include yellow-bar;
  }
  @include rules-for-wide-view {
    padding: $main-header-vertical-padding ($standard-page-margin + $yellow-bar-width);
  }
  z-index: 1;
}

.main-header-scroll-to-top {
  position: absolute;
  right: 2rem;
  line-height: 2rem;
  opacity: 0;
  transition-property: opacity;
  transition-duration: $standard-transition-duration;
  @include rules-for-medium-view-and-wide-view {
    display: none;
  }
}

.main-header.mod__scrolled-down .main-header-scroll-to-top {
  opacity: 1;
}

.main-header-menu-opener {
  position: absolute;
  left: 2rem;
  line-height: 2rem;
  @include rules-for-medium-view-and-wide-view {
    display: none;
  }
}

.main-header-logo {
  line-height: 2rem;
  font-weight: 500;
  font-style: italic;
  font-size: 1.5rem;
  text-decoration: none;
  color: $text-color;
  @include rules-for-medium-view-and-wide-view {
    position: absolute;
    left: ($standard-page-margin + $yellow-bar-width);
    line-height: 3.375rem;
  }
}

.main-header-logo-des {
  font-family: $serif-face;
  color: $color-red;
}

.main-header nav {
  font-size: 1.125rem;
  line-height: $main-header-interior-height;
  height: 0;
  overflow: hidden;
  @include rules-for-medium-view-and-wide-view {
    padding: 0 0 0 120px;
    height: auto;
    text-align: right;
  }
  @include rules-for-wide-view {
    padding: 0 120px;
    text-align: center;
  }
}

.main-header nav.mod__opened {
  height: auto;
}

.main-header nav ul {
  margin: 0;
  padding: 0 2rem;
  display: block;
  list-style: none;
  @include rules-for-medium-view-and-wide-view {
    padding: 0;
    height: $main-header-interior-height;
  }
}

.main-header nav ul li {
  @include rules-for-medium-view-and-wide-view {
    display: inline-block;
    padding: 0 5px;
  }
  @include rules-for-wide-view {
    padding: 0 10px;
  }
}

.main-header nav ul li a {
  font-family: $serif-face;
  color: $text-color;
  font-weight: normal;
  font-style: normal;
  display: block;
  @include rules-for-medium-view-and-wide-view {
    display: inline-block;
    padding: 0 5px;
  }
}

.main-header nav ul li a.selected {
  color: $increased-emphasis-color;
  text-decoration: none;
}
