.intro-block {
  max-width: $max-text-block-width;
  margin: 3rem auto 4.5rem;
  &.mod__center-wide {
    max-width: none;
    text-align: center;
  }
  &.mod__no-bottom-margin {
    margin-bottom: 0;
  }
}

.intro-block .text-title {
  margin-bottom: 3rem;
}

.intro-block.mod__center-wide .text-title {
  margin-bottom: 0.75rem;
}

.intro-block + hr {
  max-width: $max-text-block-width;
}
