.inside-intercom-appearances {
  @include twelve-columns-to-eight-columns-grid;
  padding: 1.5rem 0 3rem;
  @include rules-for-medium-view-and-wide-view {
    padding-top: 3rem;
  }
}

.inside-intercom-appearance {
  @include four-col-item-in-grid;
  margin-top: 0;
  @include rules-for-medium-view-and-wide-view {
    margin-top: 1.5rem;
  }
}

.inside-intercom-appearance-text-description {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
