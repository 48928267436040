.speaking-appearances {
  @include twelve-columns-to-eight-columns-grid;
}

.speaking-appearance {
  @include four-col-item-in-grid;
}

.speaking-appearance-slide {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 0;
  padding-top: 56.25%;
}

.speaking-appearance h2 {
  line-height: 1.875rem;
  margin: 1.5rem 0 0;
}

.speaking-appearance-description p:first-child {
  margin-top: 0.75rem;
}

/* Upper section */
.speaking-upper {
  @include twelve-columns-to-eight-columns-grid;
  @include rules-for-medium-view-and-wide-view {
    padding-bottom: 3rem 0 $rhythm;
  }
}

.speaking-upper-video-wall {
  display: none;
  @include rules-for-wide-view {
    display: block;
    overflow: hidden;
    position: relative;
    @include span-n-grid-columns(4);
  }
}

.speaking-upper-video-wall video {
  object-fit: cover;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.speaking-upper-regular-flow {
  @include rules-for-medium-view-and-wide-view {
    @include span-n-grid-columns(8);
  }
}

.speaking-upper-first-two-appearances {
  @include adapting-grid(8);
}
