body.home {
  min-height: 100vh;
  padding: 2rem;
  border-top: 2rem solid $color-yellow;
  box-sizing: border-box;
  background-image: url($mobile-home-image-url);
  background-position: bottom center;
  background-size: 140px 87px;
  background-repeat: no-repeat;
  @include rules-for-medium-view-and-wide-view {
    background-image: none;
    padding: 3rem;
    padding: 0 0 0 45px;
    display: flex;
    align-items: stretch;
    border-top: 0;
    @include yellow-bar;
  }
}

.home-des-image {
  @include rules-for-medium-view-and-wide-view {
    flex: 2 2 40%;
    background-color: #fff;
    background-image: linear-gradient(90deg, rgba($color-dark-gray, 0) 0%, rgba($color-dark-gray, .2) 100%), url($home-image-url);
    background-repeat: repeat-y, no-repeat;
    background-position: right 0, center;
    background-size: 48px 1px, cover;
  }
  @include rules-for-wide-view {
    flex-basis: 28.57%;
  }
}

.home-des-text {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  @include rules-for-medium-view-and-wide-view {
    padding: 3rem;
    flex: 3 3 60%;
    display: flex;
    font-size: 1.875rem;
    line-height: 3.375rem;
  }
  @include rules-for-wide-view {
    padding: 6.125rem;
    flex: 5 5 71.43%;
  }
}

.home-des-text em {
  color: $heading-color;
}

.home-des-text-inner {
  align-self: center;
}
